@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #3d3d3d;
}

.text-stroke {
  -webkit-text-stroke: 1px #000000;
}
